import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Field } from 'formik';
import Button from '../ui/Button';
import styles from './FileUpload.module.scss';
import uploadIcon from './images/upload.svg';
import ImageEditThumbnails from '../ui/ImageEditThumbnails';

//set indicates if this is part of a radio button set, expected to contain name (name of radio group), value (when selected), empty (value when empty)
const FileUpload = ({ setFieldValue, maxFiles = 2, set }) => {
  const { name: setName, value: setValue, empty: setEmptyValue } = set;
  const [fileCollection, setFileCollection] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const onDrop = useCallback(
    (acceptedFiles) => {
      const acceptedFilesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      const totalFiles = [...acceptedFilesWithPreview, ...fileCollection];
      if (totalFiles.length > maxFiles) {
        //removing first items
        totalFiles.splice(maxFiles, totalFiles.length - maxFiles);
      }
      setFileCollection(totalFiles);
      setFieldValue('uploadedFiles', totalFiles);
      set && setFieldValue(setName, setValue);
    },
    [fileCollection]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop,
    maxFiles: maxFiles,
    multiple: !!maxFiles,
  });

  const imageURLs = fileCollection.map((file) => {
    return file.preview;
  });

  const onChangeMainFileIndex = (index) => {
    setMainImageIndex(index);
    setFieldValue('mainImageIndex', index);
  };

  const onUpdatedFiles = (filesUrls) => {
    const updatedFiles = fileCollection.filter((file) => filesUrls.indexOf(file.preview) !== -1);
    setFileCollection(updatedFiles);
    setFieldValue('uploadedFiles', updatedFiles);
    set && updatedFiles.length === 0 && setFieldValue(setName, setEmptyValue);
  };

  return (
    <div className={styles.fileUploadContainer}>
      <div {...getRootProps()} className={styles.fileUpload}>
        <ImageEditThumbnails
          urls={imageURLs}
          mainImageIndex={mainImageIndex}
          onUpdated={onUpdatedFiles}
          onChangeMain={onChangeMainFileIndex}
        />
        <div className={styles.fileUploadInstructions}>
          <input name='uploadedFiles' id='uploadedFiles' {...getInputProps()} />
          {set ? <Field name={setName} id={`${setName}-custom`} type='radio' value={setValue} /> : null}
          <img className={styles.uploadIcon} src={uploadIcon} alt='Upload images' />
          <p className={styles.instructionsTitle}>Drag and drop here</p>
          <p className={styles.instructions}>Or click to select {maxFiles === 1 ? 'a file' : 'files'}</p>
          <Button design='secondary' className={styles.uploadButton}>
            Upload
          </Button>
        </div>
      </div>
      <p className={styles.instructionsNotes}>Accepted file types: .jpg .png</p>
    </div>
  );
};

export default FileUpload;
