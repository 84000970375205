import { ADD_LIST_REQUEST } from './listConstants';

/**
 * We want to add a list
 * @param {object} data                 list information
 * @param {string} data.category        list category
 * @param {string} data.description     list description
 * @param {string} data.title           list title
 * @param {string} data.uid             list author id
 * @param {boolean} data.sharingSettings list sharingSettings
 * @param {string} data.imageType       list imageType (color | custom | library)
 * @param {string} data.pickedColor     list color (if imageType==color)
 * @param {array} data.uploadedFiles    list uploadedFiles (if imageType==custom)
 */

export function addListRequest(data, mock) {
  return { type: ADD_LIST_REQUEST, data, mock };
}
