import { ADD_MOKU_REQUEST } from '../actions/mokuConstants';
import { SENDING_REQUEST, SET_ERROR, SET_RESPONSE } from '../../actions/constants';
import { SUCCESS_STATUS } from '@mokuroku/mokuroku-commons/dictionaries/statuses';
import { take, call, put, fork } from 'redux-saga/effects';
import mokuModels from '../models/mokuModels';
import { errorsDictionary } from '@mokuroku/mokuroku-commons/dictionaries/errors';

/*
  Sagas that handle mokus
  Note: If you need to add another saga, you will need to import it from the main sagas.js file in this directory and include it in the root method
*/

/**
 * Add Moku saga
 */
export function* addMokuFlow() {
  //this saga is always listening for actions
  //the while will not go infinite since as a generator this stops each yield
  while (true) {
    const request = yield take(ADD_MOKU_REQUEST);
    const data = request.data;
    const response = yield call(manageMokus, data, ADD_MOKU_REQUEST, request.mock);
    if (response) {
      yield put({ type: SET_RESPONSE, response: response.data });
    }
  }
}

/**
 * Handling list requests
 * @param {object} data           User's data
 * @param {string} callType       Needs to be included in requests object, pointing to an auth method
 * @param {string} mock           Indicates if this is a mock call, doesn´t affect sagas just pass it through
 */

export function* manageMokus(data, requestType, mock) {
  //We are sending a request, a chance to show loaders
  yield put({ type: SENDING_REQUEST, sending: true });
  let response;
  const requests = {
    ADD_MOKU_REQUEST: mokuModels.addMoku,
  };
  try {
    if (!requests[requestType]) {
      console.warn('list requestType not valid:', requestType, requests, requests[requestType]);
      throw new Error('list requestType not valid:', requestType);
    }
    response = yield call(requests[requestType], data, mock);
    return response;
  } catch (error) {
    yield put({
      type: SET_ERROR,
      error: { origin: 'auth', type: requestType, message: (error && error.message) || error },
    });
  } finally {
    yield put({ type: SENDING_REQUEST, sending: false });
  }
}
