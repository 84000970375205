import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logout from '../../userAuth/components/Logout';
import listModels from '../../mokus/models/listModels';

const HomeUser = ({ authData }) => {
  useEffect(() => {
    listModels.getUserLists().then((res) => {
      console.log(res);
    });
    listModels.getUserList('KElCbK1dlB5KB5D30wEo').then((res) => {
      console.log(res);
    });
  }, []);

  return (
    <>
      <div>
        <h2>Welcome to Mokuroku {authData?.user?.username}!</h2>
      </div>
      <ul>
        <li>
          <Link to='/list/create'>Create your first list</Link>
        </li>
        <li>
          <Link to='/moku/create'>Create your first moku</Link>
        </li>
      </ul>
      <div>We will send you an email when the platform is fully ready.</div>
      <Logout />
    </>
  );
};

function mapStateToProps(state) {
  return {
    authData: state.authData,
  };
}

export default connect(mapStateToProps)(HomeUser);
