import React from 'react';
import styles from './FullForm.module.scss';
import { Form } from 'formik';

const FullForm = ({ children, className = '', disabled, ...props }) => {
  const disabledClass = disabled ? styles.disabledForm : '';
  return (
    <Form className={`${styles.fullForm} ${className} ${disabledClass}`} {...props}>
      <fieldset disabled={props.disabled}>{children}</fieldset>
    </Form>
  );
};

export default FullForm;
