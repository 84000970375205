import React from 'react';
import Button from '../Button';
import trashIcon from '../../FormElements/images/trash.svg';
import starIconYellow from './images/star-yellow.svg';
import starIcon from './images/star.svg';
import starEmptyIcon from './images/star-o.svg';
import styles from './ImageEditThumbnails.module.scss';
const ImageEditThumbnail = ({ url, main, onRemove, index, onChangeIndex, star = true }) => {
  const onRemoveLocal = (event) => {
    event.stopPropagation();
    onRemove(url);
  };
  const onChangeIndexLocal = (event) => {
    event.stopPropagation();
    if (onChangeIndex) onChangeIndex(index);
  };
  return (
    <>
      <figure className={styles.imageEditThumbnail}>
        {star ? (
          <aside className={styles.starIcon}>{main ? <img src={starIconYellow} /> : <img src={starEmptyIcon} />}</aside>
        ) : null}
        {<div className={styles.imagePlaceholder} style={{ backgroundImage: `url(${url})` }}></div>}
        <figcaption className={styles.controls}>
          {star ? (
            <Button design='star' disabled={main} onClick={onChangeIndexLocal}>
              <img src={starIcon} />
            </Button>
          ) : null}

          <Button design='destructive' onClick={onRemoveLocal}>
            <img src={trashIcon} />
          </Button>
        </figcaption>
      </figure>
    </>
  );
};

export default ImageEditThumbnail;
