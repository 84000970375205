import { SUCCESS_STATUS } from '@mokuroku/mokuroku-commons/dictionaries/statuses';
import { errorsMessagesDictionary } from '@mokuroku/mokuroku-commons/dictionaries/errors';
import { uploadFile } from '../../middleware/storage';
import { LIST_CUSTOM_IMAGE, LIST } from '../actions/listConstants';
import addList from './graphql/mutations/addList';
import getUserLists from './graphql/queries/getUserLists';
import getUserList from './graphql/queries/getUserList';

const host = `https://${process.env.HOST}:${Number(process.env.PORT)}/`;

const listModels = {
  addList(data) {
    //upload image if it exists and if user selected custom image
    if (data.uploadedFiles[0] && data.imageType === LIST_CUSTOM_IMAGE) {
      return uploadFile(data.uploadedFiles[0], LIST).then((res) => {
        data.customImage = res.url;
        delete data.pickedColor;
        return addList(data);
      });
    } else {
      return addList(data);
    }
  },
  getUserLists() {
    return getUserLists();
  },
  getUserList(id) {
    return getUserList(id);
  },
};

export default listModels;
