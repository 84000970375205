import React from 'react';
import LoadingIcon from '../LoadingIcon';
import styles from './Button.module.scss'; //need to import even if not using classes, to apply default styles

const Button = ({
  classNames = [],
  className,
  design = 'primary',
  children,
  type = 'button',
  onClick,
  loading,
  disabled,
}) => {
  let icon = null;
  className && classNames.push(className);
  if (loading) {
    classNames.indexOf(styles.loading) === -1 && classNames.push(styles.loading);
    disabled = true;
    icon = <LoadingIcon />;
  }
  let buttonStyle = styles.button;
  if (design === 'secondary') {
    buttonStyle += ` ${styles.secondary}`;
  } else if (design === 'destructive') {
    buttonStyle += ` ${styles.destructive}`;
  } else if (design === 'star') {
    buttonStyle += ` ${styles.star}`;
  }
  return (
    <button className={classNames.join(' ') + ' ' + buttonStyle} type={type} onClick={onClick} disabled={disabled}>
      {icon}
      {children}
    </button>
  );
};

export default Button;
