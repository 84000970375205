import { gql } from '@apollo/client';
import client from '../../../../models/graphql/apolloClient';

const ADD_MOKU = gql`
  mutation addMoku(
    $description: String
    $title: String
    $url: String
    $imagesType: String
    $urlImages: [String]
    $customImages: [String]
    $list: ID
  ) {
    addMoku(
      description: $description
      title: $title
      url: $url
      imagesType: $imagesType
      urlImages: $urlImages
      customImages: $customImages
      list: $list
    ) {
      title
    }
  }
`;

const addMoku = (variables) => {
  return client.mutate({
    mutation: ADD_MOKU,
    variables: variables,
  });
};

export default addMoku;
