import { gql } from '@apollo/client';
import client from '../../../../models/graphql/apolloClient';

const ADD_LIST = gql`
  mutation addList(
    $category: String
    $description: String
    $title: String!
    $sharingSettings: String!
    $imageType: String
    $pickedColor: String
    $customImage: String
  ) {
    addList(
      category: $category
      description: $description
      title: $title
      sharingSettings: $sharingSettings
      imageType: $imageType
      pickedColor: $pickedColor
      customImage: $customImage
    ) {
      title
    }
  }
`;

const addList = (variables) => {
  return client.mutate({
    mutation: ADD_LIST,
    variables: variables,
  });
};

export default addList;
