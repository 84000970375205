import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateListForm from './CreateListForm';
import commonStyles from '../../../theme/common.module.scss';
import { addListRequest } from '../../actions/listActions';
import styles from './CreateList.module.scss';
import { ADD_LIST_REQUEST } from '../../actions/listConstants';
import { LIST_COLOR } from '../../actions/listConstants';
//to prefill form
const testData = process.env.NODE_ENV === 'development' && true; // change to false when testing without data
const mockRequestError = '';
const mockRequestSuccess = '';

const CreateList = ({ dispatch, networkData }) => {
  const initialData = {
    title: testData ? 'The best list of mokus ever created' : '',
    description: testData
      ? 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'
      : '',
    sharingSettings: testData ? 'public' : 'public',
    category: testData ? 'i-want-for-myself' : '',
    imageType: LIST_COLOR,
    pickedColor: '#F15922',
    uploadedFiles: [],
  };

  const serverError =
    networkData.error && networkData.error.type === ADD_LIST_REQUEST && networkData.error.message && !mockRequestError
      ? networkData.error.message
      : null;

  const onSubmit = (values) => {
    console.log('submit form', values);
    dispatch(addListRequest(values));
  };

  if (networkData.response && networkData.response.addList) {
    //List was added properly, we can redirect to list of lists
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }
  return (
    <section className={`${commonStyles.mainGrid} ${styles.createList}`}>
      <h1 className={`${styles.title}`}>Create list</h1>
      <CreateListForm values={initialData} onSubmit={onSubmit} serverError={serverError} />
    </section>
  );
};

function mapStateToProps(state) {
  return {
    networkData: state.networkData,
  };
}

export default connect(mapStateToProps)(CreateList);
