import { uploadFile } from '../../middleware/storage';
import { MOKU_IMAGE_CUSTOM, MOKU } from '../actions/mokuConstants';
import addMoku from './graphql/mutations/addMoku';

const mokuModels = {
  addMoku: async (data) => {
    //upload image if it exists and if user selected custom image
    if (data.imagesType === MOKU_IMAGE_CUSTOM) {
      const uploadPromises = data.uploadedFiles.map((file) => {
        return uploadFile(file, MOKU);
      });
      return Promise.all(uploadPromises).then((responses) => {
        const customImages = responses.map((response) => {
          return response.url;
        });
        data.customImages = customImages;
        return addMoku(data);
      });
    } else {
      return addMoku(data);
    }
  },
};

export default mokuModels;
