import { errorsDictionary } from '@mokuroku/mokuroku-commons/dictionaries/errors';
import getUser from '../models/graphql/queries/getUser';

export function isNode() {
  // http://stackoverflow.com/questions/4224606/how-to-check-whether-a-script-is-running-under-node-js
  return typeof process !== 'undefined' && process.release && process.release.name === 'node';
}

export function verifyLocalDev() {
  //This is only meant to run in localhost (dev env), when the GraphQL URI for BE is also localhost
  getUser('fakeid').catch((err) => {
    if (err.message !== errorsDictionary.USER_UNKNOWN) {
      const chalk = require('chalk');
      console.error(
        chalk.red(
          `\n\n\n******************************
            \nERROR on calling BE endpoints. Localhost service not responding
            \nDo you have the BE running locally with firebase?\n\n******************************\n\n`
        )
      );
    }
  });
}

export function cleanObject(obj) {
  //clean an object from its null / undefined / empty array / empty object properties
  //Warning: just one level of depth
  const cleanObj = { ...obj };
  for (let key in cleanObj) {
    if (!cleanObj[key] || Object.keys(cleanObj[key]).length === 0) {
      delete cleanObj[key];
    }
  }
  return cleanObj;
}

export function debounce(func, wait = 1000) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}
