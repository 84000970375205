import { gql } from '@apollo/client';
import client from '../../../../models/graphql/apolloClient';

const GET_LIBRARY_IMAGES = gql`
  query LIBRARY_IMAGES($availableFor: String!) {
    libraryImages(availableFor: $availableFor) {
      name
      urls {
        thumb200x200
      }
    }
  }
`;

const getLibraryImages = (availableFor) => {
  return client.query({
    query: GET_LIBRARY_IMAGES,
    variables: { availableFor },
  });
};

export default getLibraryImages;
