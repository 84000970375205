import React, { useState } from 'react';
import ImageEditThumbnail from './ImageEditThumbnail';
import styles from './ImageEditThumbnails.module.scss';

const ImageEditThumbnails = ({ urls, mainImageIndex, onUpdated, onChangeIndex }) => {
  if (urls.length === 0) {
    return null;
  }
  const onRemove = (thumbnailURL) => {
    const updatedURLs = [...urls];
    updatedURLs.splice(updatedURLs.indexOf(thumbnailURL), 1);
    onUpdated(updatedURLs);
  };

  return (
    <ul className={styles.thumbnails}>
      {urls.map((imageURL, index) => {
        return (
          <li className={styles.thumbnailContainer} key={`editthumb${index}`}>
            <ImageEditThumbnail
              url={imageURL}
              main={mainImageIndex == index}
              onRemove={onRemove}
              onChangeIndex={onChangeIndex}
              index={index}
              star={urls.length > 1}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default ImageEditThumbnails;
