import React, { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { Field } from 'formik';
import styles from './ThumbnailsPicker.module.scss';
import { LIST_COLOR } from '../../../mokus/actions/listConstants';
import imageModels from '../../../mokus/models/imageModels';

//TODO: need to consume this from BE
const ThumbnailsPicker = ({
  colorSelector = true,
  name = 'radio-thumbs-list',
  setFieldValue,
  colorValue,
  disabled,
}) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [colorPickerOn, setColorPickerOn] = useState(false);
  const [selectedColor, setSelectedColor] = useState(colorValue);
  const openColorPicker = () => {
    setColorPickerOn(true);
  };
  const handleColorPickerChange = (color) => {
    setSelectedColor(color.hex);
    setFieldValue('pickedColor', color.hex);
  };
  const closeColorPicker = () => {
    setColorPickerOn(false);
  };

  useEffect(() => {
    imageModels.getLibraryImages('lists').then((res) => {
      setThumbnails(res.data.libraryImages);
    });
  }, []);

  return (
    <ul className={`${styles.thumbnailsPicker} ${disabled && styles.disabled}`}>
      {colorSelector ? (
        <li>
          {colorPickerOn ? (
            <div className={styles.colorPicker}>
              <div className={styles.colorPickerCover} onClick={closeColorPicker}></div>
              <ChromePicker disableAlpha={true} color={selectedColor} onChangeComplete={handleColorPickerChange} />
            </div>
          ) : null}
          <label>
            <div className={styles.colorContainer} onClick={openColorPicker}>
              <div className={styles.colorSelector} style={{ backgroundColor: selectedColor }}></div>
              <Field name='pickedColor' id='pickedcolor' type='hidden' />
            </div>
            <div className={styles.radioLabel}>
              <Field name={name} id={`${name}-color`} type='radio' value={LIST_COLOR} />
              Solid color
            </div>
          </label>
        </li>
      ) : null}
      {thumbnails.map((thumb, index) => {
        return (
          <li key={`${name}-items-${index}`}>
            <label key={`${name}-${index}`}>
              <img src={thumb.urls.thumb200x200} />
              <Field name={name} id={`${name}-${index}`} type='radio' value={`thumb-${index}`} />
              {thumb.name}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default ThumbnailsPicker;
