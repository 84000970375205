import { LIST } from '../../mokus/actions/listConstants';
import { MOKU } from '../../mokus/actions/mokuConstants';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

const verbose = process.env.NODE_ENV === 'development' && true;

const buckets = {
  [LIST]: 'gs://mokuroku-staging-users',
  [MOKU]: 'gs://mokuroku-staging-users',
  default: 'gs://mokuroku-staging-users',
};

export function firebaseUpload(file, type) {
  return new Promise((resolve, reject) => {
    const user = (firebase.auth().currentUser && firebase.auth().currentUser.uid) || 'unknown';
    const directory = user;
    const filename = `${type}-${Date.now()}${file.name.substr(file.name.lastIndexOf('.'))}`;
    const storageBucket = buckets[type] || buckets.default;
    const metadata = {
      contentType: file.type,
    };
    const storage = firebase.app().storage(storageBucket);
    const storageRef = storage.ref();
    const uploadTask = storageRef.child(`${directory}/${filename}`).put(file, metadata);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        verbose && console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            verbose && console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            verbose && console.log('Upload is running');
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
        verbose && console.log('error on upload', error);
        reject(error);
      },
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          verbose && console.log('File available at', downloadURL);
          resolve({ url: downloadURL });
        });
      }
    );
  });
}
