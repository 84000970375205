import { LIST } from '../actions/listConstants';
import getLibraryImages from './graphql/queries/getLibraryImages';

const imageModels = {
  getLibraryImages(availableFor) {
    return getLibraryImages(availableFor);
  },
};

export default imageModels;
