import React from 'react';
import styles from './FullField.module.scss';
import errorStyles from './Errors.module.scss';
import Label from './Label';
import LoadingIcon from '../ui/LoadingIcon';
import { Field } from 'formik';

const InputField = ({ name, type, error, label, loading, ...props }) => {
  //data-test='form-error' is meant to be used for unit testing error messages
  const fieldClass = loading ? styles.loading : '';
  const loadingIcon = loading && <LoadingIcon classNames={[styles.loadingIcon]} />;
  return (
    <div className={styles.block} data-field={name + '-field'}>
      <Label htmlFor={name}>{label}</Label>
      <div className={styles.inputContainer}>
        {loadingIcon}
        <Field name={name} id={name} type={type || 'text'} className={fieldClass} {...props} />
      </div>
      {error ? (
        <div className={`${errorStyles.message} form-error`} data-test='form-error'>
          {error}
        </div>
      ) : null}
    </div>
  );
};

const Checkbox = ({ name, validate, type, error, label }) => {
  //checkbox is different since it has label in different place and different className
  return (
    <div className={styles.checkboxBlock} data-field={name + '-field'}>
      <Field name={name} id={name} validate={validate} type={type || 'text'} />
      <Label htmlFor={name} className={error && errorStyles.checkbox}>
        {label}
      </Label>
      {error ? <div className={errorStyles.message}>{error}</div> : null}
    </div>
  );
};

const Radio = ({ name, validate, error, label, options, className }) => {
  //radio is different since it multiple fields in it
  return (
    <div className={`${styles.radioBlock} ${className}`} data-field={name + '-field'}>
      {label ? (
        <Label htmlFor={name} className={error && errorStyles.checkbox}>
          {label}
        </Label>
      ) : null}
      {options.map((option, index) => (
        <label key={`radio-${name}${index}`}>
          <Field validate={validate} name={name} id={`${name}${index}`} type='radio' value={option.value} />
          {option.label}
        </label>
      ))}

      {error ? <div className={errorStyles.message}>{error}</div> : null}
    </div>
  );
};

const Textarea = ({ name, validate, error, label }) => {
  //data-test='form-error' is meant to be used for unit testing error messages
  return (
    <div className={styles.block} data-field={name + '-field'}>
      <Label htmlFor={name}>{label}</Label>
      <div className={error && errorStyles.block}>
        <Field name={name} id={name} validate={validate} as='textarea' />
      </div>
      {error ? (
        <div className={`${errorStyles.message} form-error`} data-test='form-error'>
          {error}
        </div>
      ) : null}
    </div>
  );
};

const FullField = (props) => {
  const { type } = props;
  if (type === 'checkbox') {
    return <Checkbox {...props} />;
  } else if (type === 'radio') {
    return <Radio {...props} />;
  } else if (type === 'textarea') {
    return <Textarea {...props} />;
  } else {
    return <InputField {...props} />;
  }
};

export default FullField;
