import React from 'react';
import { Formik } from 'formik';
import FullForm from '../../../components/FormElements/FullForm';
import FullField from '../../../components/FormElements/FullField';
import Button from '../../../components/ui/Button';
import ErrorBlock from '../../../components/ui/ErrorBlock';
import Label from '../../../components/FormElements/Label';
import Select from '../../../components/FormElements/Select';
import FileUpload from '../../../components/FormElements/FileUpload';
import styles from './CreateList.module.scss';
import * as Yup from 'yup';
import ThumbnailsPicker from '../../../components/ui/ThumbnailsPicker';
import { LIST_CUSTOM_IMAGE } from '../../actions/listConstants';

const listSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too Short!')
    .max(250, 'Too Long!')
    .required('Required'),
  description: Yup.string().max(500, 'Too long!'),
  sharingSettings: Yup.string()
    .required('Required')
    .oneOf(['public', 'private'], 'Select your sharing settings'),
  imageType: Yup.string().required('Required'),
});

const CreateListForm = (props) => {
  const { loading } = props;
  return (
    <Formik enableReinitialize initialValues={props.values} validationSchema={listSchema} onSubmit={props.onSubmit}>
      {({ errors, touched, values, setFieldValue }) => (
        <FullForm className={styles.infoForm}>
          <FullField
            label='Title'
            name='title'
            error={touched.title ? errors.title : null}
            value={values && values.title}
          />
          <FullField
            label='Description'
            name='description'
            error={touched.description ? errors.description : null}
            value={values && values.description}
            type='textarea'
          />
          <FullField
            label=''
            options={[
              {
                label: (
                  <>
                    Public <strong>(so you can share it!)</strong>
                  </>
                ),
                value: 'public',
              },
              { label: 'Private', value: 'private' },
            ]}
            type='radio'
            name='sharingSettings'
            value={values.sharingSettings}
            error={touched.sharingSettings ? errors.sharingSettings : null}
            className='multiLineRadio'
          />
          <Label htmlFor='category'>Category</Label>
          <Select
            name='category'
            id='category'
            error={touched.category ? errors.category : null}
            value={values.category}
          >
            <option value='i-want-for-myself'>I want for myself</option>
            <option value='i-want-for-someone-else'>I want for someone else</option>
            <option value='just-want-to-save-it'>Just want to save it</option>
            <option value='i-need-to-make-something'>I need to make something</option>
            <option value='i-need-to-watch-later'>I need to watch later</option>
            <option value='other'>Other</option>
          </Select>
          <div className={styles.predefinedImages}>
            <Label htmlFor='category'>Image</Label>
            <p>Select one:</p>
            <ThumbnailsPicker
              name='imageType'
              value={values.imageType}
              colorValue={values.pickedColor}
              error={touched.sharingSettings ? errors.sharingSettings : null}
              setFieldValue={setFieldValue}
              disabled={values.imageType === LIST_CUSTOM_IMAGE}
            />
            <p>or:</p>
          </div>
          <FileUpload
            setFieldValue={setFieldValue}
            maxFiles={1}
            set={{ name: 'imageType', value: LIST_CUSTOM_IMAGE, empty: 'color' }}
          />
          <Button type='submit' loading={loading}>
            Submit
          </Button>
          <ErrorBlock>{props.serverError}</ErrorBlock>
        </FullForm>
      )}
    </Formik>
  );
};

export default CreateListForm;
