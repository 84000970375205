import React from 'react';
import styles from './ErrorBlock.module.scss';

const ErrorBlock = ({ classNames = [], children }) => {
  classNames.push(styles.serverError);
  if (!children) {
    return null;
  }
  return <div className={classNames.join(' ')}>{children}</div>;
};

export default ErrorBlock;
