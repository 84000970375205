import { gql } from '@apollo/client';
import client from '../../../../models/graphql/apolloClient';

const GET_URL_CONTENTS = gql`
  query urlContents($url: String) {
    urlContents(url: $url) {
      description
      title
      mainImageIndex
      images
    }
  }
`;

const getURLContents = (url) => {
  return client.query({
    query: GET_URL_CONTENTS,
    variables: { url },
  });
};

export default getURLContents;
