import { gql } from '@apollo/client';
import client from '../../../../models/graphql/apolloClient';

const GET_USER_LISTS = gql`
  query userLists {
    userLists {
      id
      category
      description
      title
      sharingSettings
      imageType
      pickedColor
      customImage
    }
  }
`;

const getUserLists = () => {
  return client.query({
    query: GET_USER_LISTS,
  });
};

export default getUserLists;
