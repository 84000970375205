import React from 'react';
import { Formik, Field } from 'formik';
import FullForm from '../../../components/FormElements/FullForm';
import FullField from '../../../components/FormElements/FullField';
import Button from '../../../components/ui/Button';
import ErrorBlock from '../../../components/ui/ErrorBlock';
import Label from '../../../components/FormElements/Label';
import Select from '../../../components/FormElements/Select';
import FileUpload from '../../../components/FormElements/FileUpload';
import { MOKU_IMAGE_NONE, MOKU_IMAGE_URL, MOKU_IMAGE_CUSTOM } from '../../actions/mokuConstants';
import styles from './CreateMoku.module.scss';
import * as Yup from 'yup';
import ImageEditThumbnails from '../../../components/ui/ImageEditThumbnails';

const mokuSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too Short!')
    .max(250, 'Too Long!')
    .required('Required'),
  description: Yup.string().max(1000, 'Too long!'),
  url: Yup.string().url('This is not a valid URL'),
  imagesType: Yup.string()
    .required('Required')
    .oneOf([MOKU_IMAGE_URL, MOKU_IMAGE_CUSTOM, MOKU_IMAGE_NONE], 'No image type specified'),
});

const urlValidation = Yup.object().shape({
  url: Yup.string()
    .required()
    .min(20)
    .url(),
});

const CreateMokuForm = (props) => {
  const { loading, loadingFields, onUpdateURLs, onChangeIndex, onChange, lists } = props;
  let urlChangeDebounce;
  const onURLChange = (e, values) => {
    const urlValue = e.currentTarget.value;
    const input = e.currentTarget;
    clearTimeout(urlChangeDebounce);
    urlChangeDebounce = setTimeout(() => {
      urlValidation.isValid({ url: urlValue }).then((valid) => {
        if (valid) {
          onChange?.url(urlValue, values, input);
        }
      });
    }, 500);
  };
  return (
    <Formik enableReinitialize initialValues={props.values} validationSchema={mokuSchema} onSubmit={props.onSubmit}>
      {({ errors, touched, values, setFieldValue }) => (
        <FullForm className={styles.infoForm} disabled={loading}>
          {!lists || lists.length > 0 ? (
            <>
              <Label htmlFor='country'>List*</Label>
              <Select
                name='list'
                id='list'
                error={touched.list ? errors.list : null}
                value={values.list}
                hideDefault={true}
              >
                {lists.map((list, index) => {
                  return (
                    <option key={`list-select-${index}`} value={list.id}>
                      {list.title}
                    </option>
                  );
                })}
              </Select>
            </>
          ) : null}
          <FullField
            label='URL'
            name='url'
            error={touched.url ? errors.url : null}
            value={values && values.url}
            onInput={(e) => {
              onURLChange(e, values);
            }}
            loading={loadingFields.url}
          />
          <FullField
            label='Title*'
            name='title'
            error={touched.title ? errors.title : null}
            value={values && values.title}
          />
          <FullField
            label='Description'
            name='description'
            error={touched.description ? errors.description : null}
            value={values && values.description}
            type='textarea'
          />
          <div className={styles.predefinedImages}>
            <Label>Images</Label>
            <Field
              className={styles.hidden}
              name='imagesType'
              id='imagesTypeNone'
              type='radio'
              value={MOKU_IMAGE_NONE}
              checked
            />
            <Field className={styles.hidden} name='imagesType' id='imagesTypeURL' type='radio' value={MOKU_IMAGE_URL} />
            {values.urlImages && values.urlImages.length > 0 ? (
              <p className={styles.notes}>*Obtained from the URL:</p>
            ) : null}
            <ImageEditThumbnails
              urls={values.urlImages}
              mainImageIndex={values.mainImageIndex}
              onUpdated={(urls) => {
                onUpdateURLs(urls, values);
              }}
              onChangeIndex={(index) => {
                onChangeIndex(index, values);
              }}
            />
          </div>
          {!values.urlImages || values.urlImages.length === 0 ? (
            <FileUpload
              setFieldValue={setFieldValue}
              maxFiles={5}
              set={{ name: 'imagesType', value: MOKU_IMAGE_CUSTOM, empty: MOKU_IMAGE_NONE }}
            />
          ) : null}

          <Button type='submit' loading={loading}>
            Submit
          </Button>
          <ErrorBlock>{props.serverError}</ErrorBlock>
        </FullForm>
      )}
    </Formik>
  );
};

export default CreateMokuForm;
