import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { errorsMessagesDictionary } from '@mokuroku/mokuroku-commons/dictionaries/errors';
import FullField from '../FormElements/FullField';
import FullForm from '../FormElements/FullForm';
import ErrorBlock from '../ui/ErrorBlock';
import DateField from '../FormElements/DateField';
import Label from '../FormElements/Label';
import Select from '../FormElements/Select';
import Button from '../ui/Button';
import { SOCIAL_AUTH_FACEBOOK, SOCIAL_AUTH_GOOGLE } from '../../models/constants';

const userInfoSchema = Yup.object().shape({
  signupMethod: Yup.string()
    .oneOf([SOCIAL_AUTH_FACEBOOK, SOCIAL_AUTH_GOOGLE], 'Social Login invalid')
    .required('Required'),
  fullName: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  username: Yup.string()
    .min(4, 'Too Short!')
    .max(25, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  country: Yup.string().required('Required'),
  birthDateDD: Yup.number()
    .typeError('Invalid birth day')
    .min(1, 'Invalid birth day')
    .max(31, 'Invalid birth day')
    .integer('Invalid birth day'),
  birthDateYYYY: Yup.number()
    .typeError('Invalid birth year')
    .min(1920, 'Invalid birth year')
    .max(2002, 'Invalid birth year')
    .integer('Invalid birth year'),
  terms: Yup.boolean().oneOf([true], 'Accept Terms & Conditions is required'),
});

const SocialUserInfoForm = (props) => {
  const { loading, signupMethod, onSubmit } = props;
  const serverErrors = {};
  serverErrors.EMAIL_ALREADY_IN_USE = props.serverError === errorsMessagesDictionary.EMAIL_ALREADY_IN_USE || null;
  serverErrors.USERNAME_ALREADY_IN_USE = props.serverError === errorsMessagesDictionary.USERNAME_ALREADY_IN_USE || null;

  //creating initialValues object without password and passwordConfirmation
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...props.values, signupMethod }}
      validationSchema={userInfoSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values }) => (
        <FullForm>
          <Field name='signupMethod' type='hidden' value={values.signupMethod} />
          <FullField
            label='Email*'
            name='email'
            error={touched.email ? errors.email || serverErrors.EMAIL_ALREADY_IN_USE : null}
            value={values.email}
          />
          <FullField
            label='Username*'
            name='username'
            error={touched.username ? errors.username || serverErrors.USERNAME_ALREADY_IN_USE : null}
          />
          <FullField
            label='Full name*'
            name='fullName'
            error={touched.fullName ? errors.fullName : null}
            value={values.fullName}
          />

          <Label htmlFor='country'>Country*</Label>
          <Select name='country' id='country' error={touched.country ? errors.country : null} value={values.country}>
            <option value='Netherlands'>Netherlands</option>
            <option value='Mexico'>Mexico</option>
          </Select>

          <DateField label='Birth date' name='birthDate' errors={errors} />
          <FullField
            label='I accept terms and conditions'
            type='checkbox'
            name='terms'
            checked={values.terms}
            error={touched.terms ? errors.terms : null}
          />
          <ErrorBlock>{props.serverError}</ErrorBlock>
          <Button type='submit' loading={loading}>
            Submit
          </Button>
        </FullForm>
      )}
    </Formik>
  );
};

export default SocialUserInfoForm;
