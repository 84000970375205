import { ADD_MOKU_REQUEST } from './mokuConstants';

/**
 * We want to add a moku
 * @param {object} data                 moku information
 * @param {string} data.url             moku url
 * @param {string} data.description     moku description
 * @param {string} data.title           moku title
 * @param {string} data.uid             moku author id
 * @param {string} data.imageType       moku imageType (url | custom)
 * @param {array} data.uploadedFiles    moku uploadedFiles (if imageType==custom)
 * @param {array[String]} data.urlImages moku urlImages (if imageType==url)
 */

export function addMokuRequest(data, mock) {
  return { type: ADD_MOKU_REQUEST, data, mock };
}
