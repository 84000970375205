import { gql } from '@apollo/client';
import client from '../../../../models/graphql/apolloClient';

const GET_USER_LIST = gql`
  query userList($id: ID) {
    userList(id: $id) {
      id
      category
      description
      title
      sharingSettings
      imageType
      pickedColor
      customImage
    }
  }
`;

const getUserList = (id) => {
  return client.query({
    query: GET_USER_LIST,
    variables: { id },
  });
};

export default getUserList;
