import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateMokuForm from './CreateMokuForm';
import commonStyles from '../../../theme/common.module.scss';
import { MOKU_IMAGE_NONE, MOKU_IMAGE_URL, MOKU_IMAGE_CUSTOM } from '../../actions/mokuConstants';
import urlContentsModels from '../../models/urlContentsModels';
import { cleanObject } from '../../../utils/utilities';
import { addMokuRequest } from '../../actions/mokuActions';
import styles from './CreateMoku.module.scss';
import { ADD_MOKU_REQUEST } from '../../actions/mokuConstants';
import listModels from '../../models/listModels';

//to prefill form
const testData = process.env.NODE_ENV === 'development' && false; // change to false when testing without data
const mockRequestError = '';
const mockRequestSuccess = '';

const CreateMoku = ({ dispatch, networkData, list }) => {
  const initialData = {
    title: testData ? 'Retro videogame console' : '',
    url: testData ? '' : '',
    description: testData ? 'This console plays all retro games including NES, SNES, Mame, NeoGeo and arcades.' : '',
    imagesType: testData ? MOKU_IMAGE_URL : MOKU_IMAGE_NONE,
    mainImageIndex: testData ? 0 : 0,
    uploadedFiles: [],
    urlImages: [],
    list: list || '',
  };

  const serverError =
    networkData.error && networkData.error.type === ADD_MOKU_REQUEST && networkData.error.message && !mockRequestError
      ? networkData.error.message
      : null;

  const [parsingURL, setParsingURL] = useState(false);
  const [formValues, setFormValues] = useState(initialData);
  const [userLists, setUserLists] = useState([]);

  const onSubmit = (values) => {
    console.log('submit form', values);
    dispatch(addMokuRequest(values));
  };

  const onChangeEvents = {
    url: (validUrl, values, urlInput) => {
      //this url has already been validated by the form's validator, so we can safely assume we can request data from the server
      setParsingURL(true);
      urlContentsModels
        .getURLContents(validUrl)
        .then((res) => {
          setParsingURL(false);
          if (res.data && res.data.urlContents) {
            const { description, title, mainImageIndex, images: urlImages } = cleanObject(res.data.urlContents);
            const updatedValues = {
              ...values,
              description: description || '',
              title: title || '',
              mainImageIndex: mainImageIndex || 0,
              urlImages: urlImages ? urlImages.slice(0, 5) : [],
              uploadedFiles: urlImages && urlImages.length > 0 ? [] : values.uploadedFiles,
              url: validUrl,
              imagesType: urlImages && urlImages.length > 0 ? MOKU_IMAGE_URL : MOKU_IMAGE_NONE,
            };
            setFormValues(updatedValues);
            urlInput.focus();
          }
        })
        .catch((err) => {
          console.log(err);
          setParsingURL(false);
          urlInput.focus();
        });
    },
  };

  const getLists = () => {
    listModels.getUserLists().then((res) => {
      if (res.data && res.data.userLists) {
        //the first list becomes the initial value, if we are showing list select
        initialData.list = res.data.userLists[0].id;
        setUserLists(res.data.userLists);
      }
    });
  };

  const onUpdateURLs = (newURLs, values) => {
    const updatedValues = { ...values, urlImages: newURLs };
    setFormValues(updatedValues);
  };

  const onChangeImageIndex = (newIndex, values) => {
    const updatedValues = { ...values, mainImageIndex: newIndex };
    setFormValues(updatedValues);
  };

  useEffect(() => {
    getLists();
  }, []);

  if (networkData.response && networkData.response.addMoku) {
    //List was added properly, we can redirect to list of lists
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }
  return (
    <section className={`${commonStyles.mainGrid} ${styles.createMoku}`}>
      <h1 className={`${styles.title}`}>Add Moku</h1>
      <CreateMokuForm
        values={formValues}
        onSubmit={onSubmit}
        serverError={serverError}
        onChange={onChangeEvents}
        loading={parsingURL}
        loadingFields={{ url: parsingURL }}
        onUpdateURLs={onUpdateURLs}
        onChangeIndex={onChangeImageIndex}
        lists={userLists}
      />
    </section>
  );
};

function mapStateToProps(state) {
  return {
    networkData: state.networkData,
  };
}

export default connect(mapStateToProps)(CreateMoku);
